// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import { LazySizes } from 'lazysizes';
import { MagnificPopup } from 'magnific-popup'
import Popper from 'popper.js';
import { Util } from 'bootstrap';
import { Slick } from 'slick-carousel';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { JqueryValidation } from 'jquery-validation';
import Player from '@vimeo/player';

$(() => {
	$(document).on('click', '.js-open-photoswipe', function (e) {
		e.preventDefault();
		var pswpElement = document.querySelectorAll('.pswp')[0];
		var gallery;
		var items = $(this).data('galeriaItems');
		if (items) {
			var array = [];
			for (var i = 0; i < items.length; i++) {
				var image = {
					src: items[i].url,
					w: items[i].width,
					h: items[i].height
				};
				array.push(image);
			}
			gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, array, {
				index: 0,
				shareEl: false,
				zoomEl: false,
				history: false,
				fullscreenEl: false,
				counterEl: false,
				arrowEl: false,
				maxSpreadZoom: 1,
				barsSize: {top:83, bottom:'auto'},
			});
			gallery.init();
		}
	});

	$(".js-hero-slider").slick({
		arrows: false,
		dots: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000,
		pauseOnHover: false,
		pauseOnFocus: false,
		speed: 1000,
		draggable: false,
		focusOnSelect: false,
		pauseOnDotsHover: false,
		swipe: false
	});

	window['openModal'] = function (_url) {
		$.magnificPopup.open({
			items: {
				src: _url,
				type: 'ajax'
			},
			removalDelay: 333,
			preloader: false,
			mainClass: 'modal-unidad-slide-in',
			closeBtnInside: false,
			alignTop: true,
			fixedContentPos: true
		});
	};
});

/* ===========================================
	Validación de formulario de contacto
/* =========================================== */
$(() => {
	$("#contactForm").validate({
		errorPlacement: function (error, element) {

		},
		onfocusout: function (element) {

		},
		submitHandler: function (form) {
			var $contactForm = $(this.currentForm);
			var urlSend = "/send.php";
			$contactForm.find("button[type='submit']").attr('disabled', 'true');
			$contactForm.find("button[type='submit']").html("ENVIANDO...");
			$.post(urlSend, {
				'g-recaptcha-response': window.grecaptcha.getResponse(),
				'nombre': $contactForm.find('#inputNombre').val(),
				'email': $contactForm.find('#inputEmail').val(),
				'mensaje': $contactForm.find('#inputMensaje').val(),
				'suscripcion': $contactForm.find('#subscribeCheck').is(":checked")
			}, function (response) {
				var res = JSON.parse(response);
				$contactForm.html('<h3>' + res.mensaje + '</h3>');
			});
		}
	});
});


/* ===========================================
	Inicializacion de mapas con Lazysizes
/* =========================================== */
$(() => {
	$(document).on('lazybeforeunveil', function (e) {
		if ($(e.target).hasClass('lazy-map')) {
			var place = {
				lat: -34.426129,
				lng: -58.662210
			};
			var map = new google.maps.Map(e.target, {
				zoom: 15,
				center: place,
				scrollwheel: false,
				navigationControl: false,
				scaleControl: false,
				mapTypeControl: false,
				mapTypeId: 'satellite'
			});
			var marker = new google.maps.Marker({
				position: place,
				map: map
			});
		}
	});
});

/* ===========================================
	Validación de formulario de newsletter
/* =========================================== */
$(() => {
	$("#smallDialogForm").validate({
		errorPlacement: function (error, element) {

		},
		onfocusout: function (element) {

		},
		submitHandler: function (form) {
			var $contactForm = $(this.currentForm);
			var urlSend = "/send_newsletter.php";
			$contactForm.find("button[type='submit']").attr('disabled', 'true');
			$contactForm.find("button[type='submit']").html("ENVIANDO...");
			$.post(urlSend, {
				'g-recaptcha-response': window.grecaptcha.getResponse(),
				'nombre': $contactForm.find('#inputNombre').val(),
				'email': $contactForm.find('#inputEmail').val()
			}, function (response) {
				var res = JSON.parse(response);
				$("#small-dialog").html('<h3>' + res.mensaje + '</h3>');
				setInterval(function(){
					$.magnificPopup.close();
				}, 2000);
			});
		}
	});
});

/* ===========================================
	Abrimos el video cuando estoy en la homepage
/* =========================================== */
$(() => {
	if($('[data-js-open-home-video]').length > 0){
		if(sessionStorage.getItem('popState') != 'shown'){
			$.magnificPopup.open({
				items: {
					src: 'https://player.vimeo.com/video/277679299?autoplay=1&loop=0&muted=1',
					type: 'iframe'
				},
				closeBtnInside: false,
				callbacks: {
					close: function() {
						setTimeout(function(){
							$.magnificPopup.open({
								items: {
									type: 'inline',
									src: '#small-dialog'
								},
								fixedContentPos: true,
								fixedBgPos: true,
								closeBtnInside: false,
								preloader: false,
								midClick: true,
								mainClass: 'modal-small-dialog',
								showCloseBtn: true
							});
						}, 200);
					},
					open: function() {
						var iframe = document.querySelector('iframe');
						var player = new Player(iframe);
						player.on('ended', function(){
							$.magnificPopup.close();
						});
					}
				}
			});
			sessionStorage.setItem('popState','shown')
		}
	}
});